import Sketch from './module.js';

import $ from 'jquery';

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

// gsap.registerPlugin(ScrollTrigger, SplitText);
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);


// let titles = [...document.querySelectorAll('h2')];

let sketch = new Sketch({
    dom: document.getElementById("container")
});

// let t1 = gsap.timeline();

// t1
//     .to(sketch.camera.position, { duration: 1,x: 6, y: 7 })
//     .to(sketch.camera.position, { duration: 1,x: 60, y: 70 })


// ScrollTrigger.create({
//       //animation: t1,
//     trigger: ".wrapper",
//     scrub: .1,
//     start: "top top",
//     end: "bottom bottom",
//     snap: 1 / (titles.length - 1),
//     onUpdate: (self) => {
//         // console.log(self.progress);
//         // console.log(sketch.model);

//         sketch.camera.position.y += 10* self.progress;
//         sketch.camera.position.x -= 10* self.progress;

//         // sketch.model.rotation.y = 2.*3.14 * self.progress;
//         // sketch.model.position.z = -2.5*Math.sin(3.14*self.progress);
//         //female Model
//         //sketch.model.position.y = -1.4+(1.5*Math.sin(3.14*self.progress));
//         //scarlett
//         //no .y movement as it is sittng on y=0

//     }
// });

// console.clear();

$("#sceneMain").on("click", function () {
    sketch.scene = sketch.sceneMain;
    console.log('Main');
})
$("#sceneGallery").on("click", function () {
    sketch.scene = sketch.sceneGallery;
    console.log('Gallery');
})

///////////////////////////////////////////////////////////////

let sections = [...document.querySelectorAll('.section')];
// for navbar
const sects = gsap.utils.toArray(".wrapper section");
const naviLinks = gsap.utils.toArray(".nav-bar ul li");

const sectionsOpa = 1;

const fadeOutActive = false;

// after gsap tween to do function ////

const passSelection = (navBarAct, i, v) => {
    sketch.parameters.svgPartSelection = i;

    // set nav bar highlighting
    for (let i = 0; i < 11; i++) {
        let prev = naviLinks;
        if (prev[i].classList.contains("active")) {
            // prevIdx = i;
            prev[i].classList.remove("active");
        }
    }
    // if(navBarAct < prevIdx) naviLinks[navBarAct+1].classList.add("active");
    // else naviLinks[navBarAct].classList.add("active");
    // console.log('ydfgfh= '+navBarAct)
    naviLinks[navBarAct+1].classList.add("active");


    if (fadeOutActive) {
        if (v == 4) {
            document.getElementById("mplayerContainer").style.display = 'none';
            // console.log(document.getElementById("vitaIframe").style.display);
        }
        else if (v == 6) {
            document.getElementById("tutorialContainer").style.display = 'none';
            // console.log(document.getElementById("vitaIframe").style.display);
        }
        else if (v == 8) {
            document.getElementById("vitaIframe").style.display = 'none';
            // console.log(document.getElementById("vitaIframe").style.display);
        }
        // console.log(sketch.parameters.svgPartSelection);
    }
}

// navigation bar
// here: https://codepen.io/GreenSock/pen/ZEJJVvR
//https://gsap.com/community/forums/topic/29935-switch-classes-of-links-when-scroll-the-page/
naviLinks.forEach((btn, index) => {
    btn.addEventListener("click", () => {
        let prevIdx;
        for (let i = 0; i < 11; i++) {
            let prev = naviLinks;
            if (prev[i].classList.contains("active")) {
                prevIdx = i;
                prev[i].classList.remove("active");
            }
        }
        btn.classList.add("active");

        //gsap.to(".wrapper", { duration: 1, scrollTo: { y: sects[index], autoKill: true, ease: "power2" } });
        if(index < prevIdx) gsap.to(window, { duration: 0.4, scrollTo: sects[index+1], autoKill: true, ease: "power2.inOut" });
        // gsap.to(window, { duration: 1, scrollTo: "#sec002", autoKill: true, ease: "power2.inOut" });
        else gsap.to(window, { duration: 1, scrollTo: sects[index], autoKill: true, ease: "power2.inOut" });

    });
});

// scroll trigger
const desktopAnimation = () => {
    let section = 0;

    // passSelection(10);
    const tl = gsap.timeline({
        default: {
            // duration: 4,
            // ease: "power2.inOut"
        },
        scrollTrigger: {
            trigger: ".wrapper",
            // start: "top center-=5%",
            // end: "bottom bottom+=5%",
            start: "top top",
            end: "bottom bottom",
            scrub: 2,
            //  scroller: "smooth-scroll",
            snap: 1 / (sections.length-1),
            // markers: true
        }
    })

    //Section 0 
    //7 0 1 5 2 3 4 9 6 8 10

    // EYESOUT VR 
    // GALLERY
    // Sound Visualizer
    // SOUND RECS
    // BUY / STORE
    // Tutorials
    // LBF
    // VITA
    // Contact
    // Imprint


    //Section 1 head
    //x= -left/+right y=+up/-down z=depth
    
    tl.to('.section00', {
        opacity: 0,
        onComplete: passSelection, onCompleteParams: [section, 7],
    });
    tl.to(sketch.camera.position, {
        x: 400, y: 700, z: 400,
    }, section);
    // tl.to(sketch.cameraTarget, { x: -600, y: -50 }, section);
    tl.to(sketch.cameraTarget, { x: 30, y: 250 }, section);
    tl.to('.section01', {
        opacity: sectionsOpa,
        onReverseComplete: passSelection, onReverseCompleteParams: [section-1, 10]
    });


    //Section 2 knee left gallery
    section += 1;
    tl.to('.section01', {
        opacity: 0,
        onComplete: passSelection, onCompleteParams: [section, 4],
    });
    tl.to(sketch.camera.position, {
        x: -400, y: -300, z: 400,
    }, section);
    // tl.to(sketch.cameraTarget, { x: 150, y: -250 }, section);
    tl.to(sketch.cameraTarget, { x: 15, y: -250 }, section);
    tl.to('.section02', {
        opacity: sectionsOpa,
        onReverseComplete: passSelection, onReverseCompleteParams: [section-1, 7]
    });


    //Section 3 neck left sound visualizer
    section += 1;
    tl.to('.section02', {
        opacity: 0,
        onComplete: passSelection, onCompleteParams: [section, 0],
    });
    tl.to(sketch.camera.position, {
        x: -800, y: 300, z: 800,
    }, section);
    // tl.to(sketch.cameraTarget, { x: 900, y: -250 }, section);
    tl.to(sketch.cameraTarget, { x: -80, y: -150 }, section);
    tl.to('.section03', {
        opacity: sectionsOpa,
        onReverseComplete: passSelection, onReverseCompleteParams: [section-1, 4]
    });


    //Section 4 middle sound recs
    section += 1;
    tl.to('.section03', {
        opacity: 0,
        onComplete: passSelection, onCompleteParams: [section, 5],
    });
    tl.to(sketch.camera.position, {
        x: 0, y: -1000, z: 400,
    }, section);
    // tl.to(sketch.cameraTarget, { x: 0, y: -50 }, section);
    tl.to(sketch.cameraTarget, { x: 0, y: -50 }, section);
    tl.to('.section04', {
        opacity: sectionsOpa,
        onReverseComplete: passSelection, onReverseCompleteParams: [section-1, 0, 4]
    });


    //Section 5 neck right STORE
    section += 1;
    tl.to('.section04', {
        opacity: 0,
        onComplete: passSelection, onCompleteParams: [section, 1, 4],
    });
    tl.to(sketch.camera.position, {
        x: 500, y: 300, z: 900,
    }, section);
    // tl.to(sketch.cameraTarget, { x: -600, y: -50 }, section);
    tl.to(sketch.cameraTarget, { x: 90, y: -50 }, section);
    tl.to('.section05', {
        opacity: sectionsOpa,
        onReverseComplete: passSelection, onReverseCompleteParams: [section-1, 5]
    });


    //Section 06 foot right Tutorials
    section += 1;
    tl.to('.section05', {
        opacity: 0,
        onComplete: passSelection, onCompleteParams: [section, 8],
    });
    tl.to(sketch.camera.position, {
        x: 600, y: -450, z: 1220,
    }, section);
    // tl.to(sketch.cameraTarget, { x: -250, y: -150 }, section);
    tl.to(sketch.cameraTarget, { x: -25, y: -250 }, section);
    tl.to('.section06', {
        opacity: sectionsOpa,
        onReverseComplete: passSelection, onReverseCompleteParams: [section-1, 1, 6]
    });


    //Section 07 arm left LbF
    section += 1;
    // console.log(document.getElementById("vitaIframe").style.display);
    tl.to('.section06', {
        opacity: 0,
        onComplete: passSelection, onCompleteParams: [section, 2, 6],
    });
    tl.to(sketch.camera.position, {
        x: -400, y: -900, z: 400,
    }, section);
    // tl.to(sketch.cameraTarget, { x: -300, y: -180 }, section);
    tl.to(sketch.cameraTarget, { x: -300, y: 180 }, section);
    tl.to('.section07', {
        opacity: sectionsOpa,
        onReverseComplete: passSelection, onReverseCompleteParams: [section-1, 8]
    });


    //Section 8 knee right Vita
    section += 1;
    tl.to('.section07', {
        opacity: 0,
        onComplete: passSelection, onCompleteParams: [section, 9],
    });
    tl.to(sketch.camera.position, {
        x: 400, y: -100, z: 400,
    }, section);
    // tl.to(sketch.cameraTarget, { x: -150, y: -250 }, section);
    tl.to(sketch.cameraTarget, { x: -15, y: -250 }, section);
    tl.to('.section08', {
        opacity: sectionsOpa,
        onReverseComplete: passSelection, onReverseCompleteParams: [section-1, 2, 8]
    });


    //Section 9 foot left Contact
    section += 1;
    tl.to('.section08', {
        opacity: 0,
        onComplete: passSelection, onCompleteParams: [section, 6, 8],
    });
    tl.to(sketch.camera.position, {
        x: -300, y: -500, z: 300,
    }, section);
    // tl.to(sketch.cameraTarget, { x: 150, y: -350 }, section);
    tl.to(sketch.cameraTarget, { x: 15, y: -450 }, section);
    tl.to('.section09', {
        opacity: sectionsOpa,
        onReverseComplete: passSelection, onReverseCompleteParams: [section-1, 9]
    });


    //Section 10 arm right Tutorials
    section += 1;
    tl.to('.section09', {
        opacity: 0,
        onStart: passSelection, onStartParams: [section, 3],
    });
    tl.to(sketch.camera.position, {
        x: 600, y: 300, z: 300,
    }, section);
    //  tl.to(sketch.cameraTarget, { x: 500, y: 50 }, section);
    tl.to(sketch.cameraTarget, { x: 350, y: 50 }, section);
    tl.to('.section10', {
        opacity: sectionsOpa,
        onReverseComplete: passSelection, onReverseCompleteParams: [section-1, 6]
    });


}
// ScrollTrigger.create();
ScrollTrigger.matchMedia({ "(prefers-reduced-motion: no-preference)": desktopAnimation });



